// eslint-disable prefer-await-to-callbacks
import React from "react";

type IntersectionCallback = (entry: IntersectionObserverEntry) => void;

type SharedIntersectionObserverOptions = {
  rootRef: React.RefObject<Element | null>;
  observerOptions?: IntersectionObserverInit;
};

let sharedObserver: IntersectionObserver | null = null;
const callbacks = new Map<Element, IntersectionCallback>();

export function getSharedIntersectionObserver({
  rootRef,
  observerOptions,
}: SharedIntersectionObserverOptions) {
  const getOrCreateObserver = () => {
    if (!rootRef.current) return null;

    if (sharedObserver && sharedObserver.root !== rootRef.current) {
      sharedObserver.disconnect();
      sharedObserver = null;
    }

    if (!sharedObserver) {
      sharedObserver = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            const callback = callbacks.get(entry.target);
            callback?.(entry);
          }
        },
        { root: rootRef.current, ...observerOptions }
      );
    }
    return sharedObserver;
  };

  const observe = (element: Element, callback: IntersectionCallback) => {
    const observer = getOrCreateObserver();
    if (!observer) return;

    if (!callbacks.has(element)) {
      callbacks.set(element, callback);
      observer.observe(element);
    }
  };

  const unobserve = (element: Element) => {
    callbacks.delete(element);
    sharedObserver?.unobserve(element);

    if (callbacks.size === 0) {
      sharedObserver?.disconnect();
      sharedObserver = null;
    }
  };

  return { observe, unobserve };
}
