import { useSelectedConversation } from "@/utils/messaging/conversation/ConversationState";
import { AnimatePresence } from "motion/react";
import { useDropzone } from "react-dropzone";
import { scrollbarWidth } from "../../styles/global.styles";
import FileDropOverlay from "../shared/FileDropOverlay";
import Chat from "./chat/chat";
import { headerHeight } from "./chat/util/chatUtils";
import { ChatFooterRef } from "./ChatFooter";
import {
  footerHalfInputHeight,
  footerPaddingHeight,
} from "./ChatFooter/ChatFooterUtils";

export default function ChatBox({
  chatFooterRef,
  onFilesAdded,
}: {
  chatFooterRef: React.RefObject<ChatFooterRef | null>;
  onFilesAdded: (files: File[]) => void;
}) {
  const conversation = useSelectedConversation();
  const contact = conversation?.participants[0];
  const acceptFileTransfers = !!contact && !contact.isChatbot;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    rootRef: chatBoxRef,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles, _rejected, _event) => {
      if (acceptFileTransfers) {
        onFilesAdded(acceptedFiles);
      }
    },
  });

  // Front row conversations are read only, no need for the extra space at the bottom
  const extraFooterHeight = contact?.isOdienceFrontRow()
    ? "0px"
    : footerHalfInputHeight;

  return (
    <div
      {...getRootProps({
        "dark-theme": "true",
        className: "chatBoxRoot",
        css: {
          // always show scrollbar so animations don't look weird
          overflowY: "scroll",
          overflowX: "hidden",
          width: "100%",
          height: `calc(100% - ${headerHeight} - ${extraFooterHeight} - ${footerPaddingHeight})`,
          padding: `0 calc(0.5em - ${scrollbarWidth}) 0 0.5em`,
          "--scrollbar-alpha": "1",
          "--scrollbar-alpha-hover": "1",
          ":focus-visible": {
            outline: "none",
          },
        },
        style: isDragActive
          ? {
              // disable scrolling when dragging a file
              overflow: "hidden",
              borderRight: `${scrollbarWidth} solid transparent`,
            }
          : undefined,
      })}
    >
      <input {...getInputProps()} />
      <AnimatePresence>
        {isDragActive && <FileDropOverlay acceptedFile={acceptFileTransfers} />}
      </AnimatePresence>
      {conversation ? (
        <Chat key={conversation.id} chatBoxRef={chatBoxRef} />
      ) : null}
    </div>
  );
}
