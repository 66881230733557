import {
  DeleteDropDownContainer,
  DropDownButton,
} from "@/components/keyPad.style";
import BodyPortal from "@/components/shared/BodyPortal";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import {
  deleteDropdownContainer,
  footerDropdownItem,
  headerDropdown,
} from "@/components/shared/Dropdown.style";
import FloatingTooltip from "@/components/shared/FloatingTooltip";
import Popover from "@/components/shared/Popover";
import { colors } from "@/styles/global.styles";
import { ReactionList } from "@/types/OmaNms";
import { atoms } from "@/utils/helpers/atoms";
import { useToast } from "@/utils/helpers/toastManager";
import NmsMessage from "@/utils/messaging/NmsMessage";
import { addOrRemoveEmoji } from "@/utils/messaging/addOrRemoveEmoji";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { formatFileSizeToHumanReadable } from "@/utils/messaging/conversation/conversationUtils";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { IconButton } from "@mui/material";
import { isAfter } from "date-fns/isAfter";
import { milliseconds } from "date-fns/milliseconds";
import { subMinutes } from "date-fns/subMinutes";
import { subSeconds } from "date-fns/subSeconds";
import { useSetAtom } from "jotai";
import { AnimatePresence } from "motion/react";
import React, {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useOnClickOutside } from "usehooks-ts";
import EmojiPicker from "../../ChatFooter/EmojiPicker";
import { messageAreaRef } from "../messageAreaRef";
import { isOverflownX } from "../util/cardUtils";
import ReactionPicker from "./ReactionPicker";

function isWithinLastMinutes(date: Date, minutes: number) {
  return isAfter(date, subMinutes(new Date(), minutes));
}

function isWithinLastSeconds(date: Date, seconds: number) {
  return isAfter(date, subSeconds(new Date(), seconds));
}

export type MessageActionsProps = {
  message: NmsMessage;
  beforeDelete: (softDelete: boolean) => void | Promise<void>;
  direction: "In" | "Out";
  reactions?: ReactionList;
  conversation: Conversation;
  onShowMessageDetails: (
    conversation: Conversation,
    message: NmsMessage
  ) => void;
};

const recallTimeoutSeconds = 30;
const editTimeoutMins = 15;
const MAX_SIZE_CUSTOM_REACTION = 500 * 1024;
const MessageActions = ({
  message,
  beforeDelete,
  reactions,
  conversation,
  onShowMessageDetails,
}: MessageActionsProps) => {
  const [emojiPicker, setEmojiPicker] = useState(false);
  const toggleEmojiPicker = () => setEmojiPicker((prev) => !prev);
  const { showToast } = useToast();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [parentMessageId, setParentMessageId] = useState("");
  const messageActionsRef = useRef<HTMLDivElement>(null!);
  const setReply = useSetAtom(atoms.messaging.messageReply);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const emojiMenuButtonRef = useRef<HTMLButtonElement>(null);
  const participants = conversation.participants;
  const isChatbot = participants[0]?.isChatbot;
  const isOdienceFrontRow = participants?.[0]?.isOdienceFrontRow();
  const incomingMessage = message.Direction === "In";
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<
    "remote" | "local" | undefined
  >();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        messageActionsRef.current &&
        !messageActionsRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (!emojiPicker) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as globalThis.Node) &&
        emojiMenuButtonRef.current &&
        !emojiMenuButtonRef.current.contains(event.target as globalThis.Node)
      ) {
        setEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPicker]);

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const handleDeleteMessage = async () => {
    await beforeDelete(false);
    await conversation.deleteMessage(message.getOriginalMessageId());
  };
  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    fileInputRef.current!.value = "";

    if (file.size > MAX_SIZE_CUSTOM_REACTION) {
      toast(
        `Custom reaction file size cannot be more than ${formatFileSizeToHumanReadable(
          MAX_SIZE_CUSTOM_REACTION
        )}`,
        {
          duration: 5000,
          position: "top-center",
          style: {
            backgroundColor: colors.secondaryBackground,
            color: colors.primaryTextColor,
          },
        }
      );
      return;
    }

    void conversation?.sendFile(file, {
      reply: {
        id: parentMessageId,
        type: "+Custom-Reaction",
      },
    });
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFilePickerClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    key: string
  ) => {
    setParentMessageId(key);
    setMoreOptionsOpen(false);
    fileInputRef.current!.click();
  };
  const handleShowMessageDetails = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    onShowMessageDetails(conversation, message);
  };
  const handleConfirmDelete = (
    event: React.MouseEvent<HTMLButtonElement>,
    isLocal = false
  ) => {
    event.stopPropagation();
    closeDropdown();
    setMoreOptionsOpen(false);
    setShowDeleteConfirmation(isLocal ? "local" : "remote");
  };

  const handleRemoveMessage = async () => {
    closeDropdown();
    const softDelete = !isWithinLastSeconds(message.Date, recallTimeoutSeconds);
    await beforeDelete(softDelete);
    const res = await (softDelete
      ? conversation?.softDeleteMessage(message.getOriginalMessageId())
      : conversation?.recallMessage(message.getOriginalMessageId()));
    showToast(
      res ? "Message was successfully unsent" : "Error un-sending message",
      "top-center",
      "1rem",
      undefined,
      undefined,
      res ? colors.primaryTextColor : colors.primaryAccentColorDarker
    );
  };

  const handleAddOrRemoveEmoji = (emoji: string) => {
    void addOrRemoveEmoji(
      emoji,
      message.getOriginalMessageId(),
      conversation,
      reactions
    );

    setEmojiPicker(false);
  };

  const moreOptionsRef = useRef<HTMLDivElement>(null);
  const [moreOptionsOpen, setMoreOptionsOpen] = useState(false);
  const toggleMoreOptions = () => {
    setMoreOptionsOpen((prev) => !prev);
  };

  // while emoji picker or more options is open, force open the dropdown
  useLayoutEffect(() => {
    messageActionsRef.current.classList.toggle(
      "force-open",
      moreOptionsOpen || emojiPicker
    );
  }, [moreOptionsOpen, emojiPicker]);

  useOnClickOutside(
    messageActionsRef,
    (e) => {
      if (emojiPickerRef.current?.contains(e.target as Node)) {
        return;
      }
      setMoreOptionsOpen(false);
    },
    "mouseup"
  );

  const moreBtnRef = useRef<HTMLButtonElement>(null!);

  const [showEditButton, setShowEditButton] = useState(
    () =>
      !message.deleted &&
      message.Direction === "Out" &&
      message["Content-Type"] === "text/plain" &&
      isWithinLastMinutes(message.Date, editTimeoutMins)
  );

  useEffect(() => {
    if (!showEditButton) return;

    // disable edit button once the editTimeoutMins are up
    const timeUntilEdit =
      milliseconds({ minutes: editTimeoutMins }) -
      (Date.now() - message.Date.getTime());
    const t = window.setTimeout(() => {
      setShowEditButton(false);
      setReply(null);
    }, timeUntilEdit);

    return () => {
      window.clearTimeout(t);
    };
  }, []);

  useLayoutEffect(() => {
    const elem = messageActionsRef.current;
    if (!elem) return;

    const checkOverflow = () => {
      if (!messageAreaRef) return;

      elem.classList.remove("overflown-x");
      const doesOverflow = isOverflownX(elem, messageAreaRef);
      elem.classList.toggle("overflown-x", doesOverflow);
    };

    checkOverflow();

    const parentContainer = elem.closest(".In, .Out");
    if (!parentContainer) return;

    parentContainer.addEventListener("mouseover", checkOverflow);
    return () => {
      parentContainer.removeEventListener("mouseover", checkOverflow);
    };
  }, []);

  return (
    <>
      <div
        ref={messageActionsRef}
        css={{
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "opacity 0.35s ease",
          height: "2em",
          zIndex: "1",
          position: "absolute",
          width: "fit-content",
          background: colors.primaryBackground,
          margin: "0 4px",
          boxShadow: `0 0 2px 2px ${colors.primaryBackground}`,
          bottom: "0",
          opacity: "0",
          pointerEvents: "none",
          // show on hover when there isn't another action forced open
          ".chat:not(:has(.force-open)) li:hover &, &.force-open": {
            opacity: "1",
            pointerEvents: "auto",
          },
          ".In &": {
            left: "100%",
            "&.overflown-x": {
              top: "100%",
              right: "0",
              bottom: "unset",
              left: "unset",
            },
          },
          ".Out &": {
            right: "100%",
            "&.overflown-x": {
              top: "100%",
              right: "unset",
              bottom: "unset",
            },
          },
        }}
      >
        <div
          css={{
            display: "flex",
            gap: "0.25em",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={onFileChange}
            style={{ display: "none" }}
          />
          {!isOdienceFrontRow && !isChatbot && !message.deleted && (
            <FloatingTooltip tooltipContent="React" offsetY={-8} mountToBody>
              <IconButton
                ref={emojiMenuButtonRef}
                css={{
                  color: colors.secondaryTextColor,
                  "&:hover": { color: colors.primaryTextColor },
                }}
                onClick={() => {
                  setMoreOptionsOpen(false);
                  toggleEmojiPicker();
                }}
              >
                <TagFacesIcon
                  css={{
                    color: emojiPicker
                      ? colors.primaryAccentColor
                      : colors.secondaryTextColor,
                    "&:hover, &:focus": {
                      color: colors.primaryAccentColor,
                    },
                    transition: "color 0.25s ease",
                  }}
                />
              </IconButton>
            </FloatingTooltip>
          )}

          {!isOdienceFrontRow && !isChatbot && !message.deleted && (
            <FloatingTooltip tooltipContent="Reply" offsetY={-8} mountToBody>
              <IconButton
                css={{
                  color: colors.secondaryTextColor,
                  "&:hover": { color: colors.primaryTextColor },
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setMoreOptionsOpen(false);
                  setReply({
                    id: message.getOriginalMessageId(),
                    message,
                    type: "Reply",
                  });
                }}
              >
                <MessageOutlinedIcon
                  css={{
                    color: colors.secondaryTextColor,
                    "&:hover, &:focus": {
                      color: colors.primaryAccentColor,
                    },
                    transition: "color 0.25s ease",
                  }}
                />
              </IconButton>
            </FloatingTooltip>
          )}
          <FloatingTooltip tooltipContent="More" offsetY={-8} mountToBody>
            <IconButton
              ref={moreBtnRef}
              onClick={toggleMoreOptions}
              css={{
                color: colors.secondaryTextColor,
                "&:hover": { color: colors.primaryTextColor },
              }}
            >
              <MoreVertIcon
                css={{
                  color: colors.secondaryTextColor,
                  "&:hover, &:focus": {
                    color: colors.primaryAccentColor,
                  },
                  transition: "color 0.25s ease",
                }}
              />
            </IconButton>
          </FloatingTooltip>

          <Popover
            ref={(floatingElem) => {
              if (!floatingElem) return;
              // don't animate the first time the dropdown is opened
              setTimeout(() => {
                // animate the dropdown when it is auto placed depending on scroll position
                floatingElem.style.transition = "transform 0.1s ease";
              });
            }}
            open={moreOptionsOpen}
            referenceElemRef={moreBtnRef}
            placements={
              incomingMessage
                ? ["top-start", "bottom-start"]
                : ["top-end", "bottom-end"]
            }
          >
            <DeleteDropDownContainer
              css={[
                deleteDropdownContainer,
                headerDropdown,
                {
                  position: "relative",
                },
              ]}
              ref={moreOptionsRef}
            >
              {!isOdienceFrontRow && (
                <>
                  <DropDownButton
                    css={footerDropdownItem}
                    onClick={(event) => {
                      handleConfirmDelete(event, true);
                    }}
                  >
                    <DeleteIcon /> Delete (locally)
                  </DropDownButton>
                  {!message.deleted && (
                    <DropDownButton
                      css={footerDropdownItem}
                      onClick={(event) => {
                        handleFilePickerClick(
                          event,
                          message.getOriginalMessageId()
                        );
                      }}
                    >
                      <AttachFileIcon /> Custom Reaction
                    </DropDownButton>
                  )}
                  {showEditButton && !message.deleted && (
                    <DropDownButton
                      css={footerDropdownItem}
                      onClick={() => {
                        setReply({
                          id: message.getOriginalMessageId(),
                          message,
                          type: "Edit",
                        });
                        setMoreOptionsOpen(false);
                      }}
                    >
                      <EditIcon /> Edit Message
                    </DropDownButton>
                  )}
                  {message.Direction === "Out" && !message.deleted && (
                    <DropDownButton
                      css={footerDropdownItem}
                      onClick={handleConfirmDelete}
                    >
                      <DeleteIcon /> Unsend
                    </DropDownButton>
                  )}
                </>
              )}
              <DropDownButton
                css={footerDropdownItem}
                onClick={(event) => {
                  handleShowMessageDetails(event);
                  setMoreOptionsOpen(false);
                }}
              >
                <InfoOutlinedIcon /> Details
              </DropDownButton>
            </DeleteDropDownContainer>
          </Popover>
        </div>
      </div>

      <Popover
        open={emojiPicker}
        referenceElemRef={emojiMenuButtonRef}
        placements={
          incomingMessage
            ? ["top-start", "bottom-start"]
            : ["top-end", "bottom-end"]
        }
        hideOnOverflow
        mountToBody
        floatingArrowProps={{
          fill: colors.secondaryBackground,
        }}
      >
        <EmojiPickers
          emojiPickerRef={emojiPickerRef}
          handleAddOrRemoveEmoji={handleAddOrRemoveEmoji}
        />
      </Popover>
      <AnimatePresence>
        <BodyPortal>
          {showDeleteConfirmation && (
            <ConfirmationPopup
              title={"Delete Message"}
              confirmationMessage={
                "Are you sure you want to delete this message?"
              }
              togglePopup={() => setShowDeleteConfirmation(undefined)}
              handleAction={() => {
                if (showDeleteConfirmation === "local") {
                  void handleDeleteMessage();
                } else {
                  void handleRemoveMessage();
                }
                setShowDeleteConfirmation(undefined);
              }}
              primaryButtonText={"Delete"}
            />
          )}
        </BodyPortal>
      </AnimatePresence>
    </>
  );
};

function EmojiPickers({
  emojiPickerRef,
  handleAddOrRemoveEmoji,
}: {
  emojiPickerRef: React.RefObject<HTMLDivElement | null>;
  handleAddOrRemoveEmoji: (emoji: string) => void;
}) {
  const [expandToAll, setExpandToAll] = useState(false);

  return expandToAll ? (
    <EmojiPicker
      ref={emojiPickerRef}
      handleEmojiClick={handleAddOrRemoveEmoji}
      visible
      uiMode="MESSAGE_REACTION"
    />
  ) : (
    <ReactionPicker
      ref={emojiPickerRef}
      handleEmojiClick={handleAddOrRemoveEmoji}
      expandToAll={() => setExpandToAll(true)}
    />
  );
}

export default MessageActions;
