import { Avatar } from "@/components/shared/Avatar";
import BodyPortal from "@/components/shared/BodyPortal";
import { deleteDropdownContainer } from "@/components/shared/Dropdown.style";
import Tooltip from "@/components/shared/FloatingTooltip";
import { Imdn, ReactionList } from "@/types/OmaNms";
import { ease } from "@/utils/ease";
import { ls } from "@/utils/helpers/localstorage";
import { convertDateToHumanReadable } from "@/utils/helpers/time";
import { insertOdd } from "@/utils/helpers/Utils";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { useContacts } from "@/utils/hooks/useContacts";
import { addOrRemoveEmoji } from "@/utils/messaging/addOrRemoveEmoji";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { getContactsByImdn } from "@/utils/messaging/conversation/conversationUtils/";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { CSSObject } from "@emotion/react";
import { AnimatePresence, motion } from "motion/react";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { colors } from "../../../../styles/global.styles";
import { ChatMessage } from "../typings";

export type StatusDivRef = {};
export function StatusTimeDiv({
  message,
  showStatus,
  conversation,
  imdns,
  reactions,
  isMessageEdited,
}: {
  showStatus: boolean;
  message: ChatMessage;
  conversation: Conversation;
  imdns?: Imdn[];
  reactions?: ReactionList;
  isMessageEdited: boolean;
} & {
  ref?: React.RefObject<StatusDivRef>;
}) {
  const show = showStatus || reactions || isMessageEdited;
  return (
    <AnimatePresence initial={false}>
      {show && (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isMessageEdited && (
            <div
              style={{
                width: "100%",
                color: colors.primaryAccentColor,
                fontSize: "0.7em",
                textAlign: message.direction === "Out" ? "end" : "start",
              }}
            >
              Edited
            </div>
          )}

          <div
            css={{
              display: "flex",
              alignItems: "center",
              flexDirection:
                message.direction === "Out" ? "row" : "row-reverse",
            }}
          >
            {showStatus && (
              <motion.div
                css={{
                  position: "relative",
                  color: colors.secondaryTextColor,
                  fontSize: "13px",
                  userSelect: "none",
                  display: "flex",
                  alignItems: "center",
                  height: "26px",
                  zIndex: 9999,
                }}
                initial={{ opacity: 0, maxHeight: 0, marginBottom: 0 }}
                animate={{ opacity: 1, maxHeight: "5em", marginBottom: "8px" }}
                transition={{
                  opacity: {
                    duration: 0.15,
                    ease,
                  },
                  maxHeight: {
                    duration: 0.35,
                    ease,
                  },
                  marginBottom: {
                    duration: 0.35,
                    ease,
                  },
                }}
              >
                {message.status === "read" ? (
                  <SeenByUI imdns={imdns} conversation={conversation} />
                ) : message.status === "delivered" ? (
                  <DeliveredToUI imdns={imdns} conversation={conversation} />
                ) : message.status === "sent" ? (
                  <SendOrDeliveredIcon />
                ) : null}
              </motion.div>
            )}

            <EmojiDisplay
              reactions={reactions}
              conversation={conversation}
              messageId={message.originalMsgId}
            />
          </div>
        </div>
      )}
    </AnimatePresence>
  );
}

function StatusAvatar(props: {
  contact?: WebGwContact;
  conversation: Conversation;
}) {
  return (
    <Avatar
      contact={props.contact}
      style={{
        fontSize: "0.65rem",
        width: "16px",
        minWidth: "16px",
        height: "16px",
        minHeight: "16px",
        transform: "translateY(1px)",
        ...(props.conversation.getIsGroupChat() && { cursor: "pointer" }),
      }}
    />
  );
}

function SeenByUI({
  imdns,
  conversation,
}: {
  imdns: Imdn[] | undefined;
  conversation: Conversation;
}) {
  if (!imdns) return null;

  if (conversation.getIsGroupChat()) {
    const contacts = getContactsByImdn(
      "Displayed",
      imdns,
      conversation.participants
    );

    return contacts.map((contact) => {
      return (
        <Tooltip
          key={contact.contact.id}
          tooltipContent={`Seen by ${contact.contact.noNameReturnPhoneNumber(contact.phoneNumber)} at ${new Date(contact.date).toLocaleString()}`}
          className="bg-tertiaryTextColor text-primaryTextColor"
        >
          <StatusAvatar contact={contact.contact} conversation={conversation} />
        </Tooltip>
      );
    });
  } else {
    const contact = conversation.participants[0];
    return (
      <Tooltip
        tooltipContent={`Seen by ${contact.noNameReturnPhoneNumber()} at ${new Date(imdns[0].imdnInfo[0].date).toLocaleString()}`}
        className="bg-tertiaryTextColor text-primaryTextColor"
      >
        <StatusAvatar contact={contact} conversation={conversation} />
      </Tooltip>
    );
  }
}

function DeliveredToUI({
  imdns,
  conversation,
}: {
  imdns: Imdn[] | undefined;
  conversation: Conversation;
}) {
  if (imdns) {
    if (conversation.getIsGroupChat()) {
      const contacts = getContactsByImdn(
        "Delivered",
        imdns,
        conversation.participants
      );

      return (
        <Tooltip
          tooltipContent={
            <>
              Delivered to:
              <br />
              {insertOdd(
                contacts
                  .concat(contacts)
                  .map(
                    (contact) =>
                      contact.contact.noNameReturnPhoneNumber(
                        contact.phoneNumber
                      ) +
                      " at " +
                      new Date(contact.date).toLocaleString()
                  ),
                (idx) => (
                  <Fragment key={idx}>
                    ,<br />
                  </Fragment>
                )
              )}
            </>
          }
          className="bg-tertiaryTextColor text-primaryTextColor"
        >
          <div style={{ cursor: "pointer" }}>
            <SendOrDeliveredIcon />
          </div>
        </Tooltip>
      );
    }
    return <SendOrDeliveredIcon />;
  }
}

function SendOrDeliveredIcon() {
  return (
    <CheckedCircle
      style={{
        opacity: "1",
      }}
    />
  );
}

const checkedCircleCSS = {
  width: "auto",
  minHeight: "26px",
  padding: "6px 0.25em",
  transition: "opacity 0.25s ease",
} as const satisfies CSSObject;

function CheckedCircle(props: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="174.211 135.309 20 20"
      width="18"
      height="18"
      fill="white"
      {...props}
      css={[checkedCircleCSS, (props as any).css]}
    >
      <path d="M 184.211 155.309 C 178.688 155.309 174.211 150.832 174.211 145.309 C 174.211 139.786 178.688 135.309 184.211 135.309 C 189.734 135.309 194.211 139.786 194.211 145.309 C 194.211 150.832 189.734 155.309 184.211 155.309 Z M 184.211 153.309 C 190.369 153.309 194.218 146.642 191.139 141.309 C 189.71 138.834 187.069 137.213 184.211 137.213 C 178.052 137.213 174.203 143.976 177.282 149.309 C 178.711 151.784 181.352 153.309 184.211 153.309 Z M 183.214 149.309 L 178.971 145.066 L 180.385 143.652 L 183.214 146.481 L 188.87 140.824 L 190.285 142.238 L 183.214 149.309 Z" />
    </svg>
  );
}

type TimeSentProps = {
  message: ChatMessage;
};
export type TimeSentRef = {
  show: (position: {
    left: number;
    right: number;
    top: number;
    incomingMessage: boolean;
  }) => void;
  hide: () => void;
};

export function TimeSent({
  ref,
  message,
}: TimeSentProps & {
  ref?: React.RefObject<TimeSentRef | null>;
}) {
  const [position, setPosition] = useState<
    Parameters<TimeSentRef["show"]>[0] | null
  >(null);

  const hide = useCallback(() => {
    setPosition(null);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", hide, {
      passive: true,
    });
    return () => window.removeEventListener("scroll", hide);
  }, []);

  useImperativeHandle<TimeSentRef, TimeSentRef>(
    ref,
    () => ({
      show: (newPosition) => {
        if (!newPosition) {
          hide();
          return;
        }
        setPosition(newPosition);
      },
      hide,
    }),
    []
  );

  return (
    <AnimatePresence>
      {!!position && (
        <BodyPortal>
          <motion.div
            style={{
              position: "fixed",
              top: position.top,
              ...(position.incomingMessage
                ? {
                    left: position.left,
                    transform: "translateY(calc(-50% - 16px))",
                  }
                : {
                    left: position.right,
                    transform: "translate(-100%, calc(-50% - 16px))",
                  }),
            }}
            css={[
              deleteDropdownContainer,
              {
                color: colors.primaryTextColor,
                background: colors.tertiaryBackground,
                pointerEvents: "none",
                padding: "0.5em 0.75em",
                width: "max-content",
                boxShadow: `0 0 4px 2px ${colors.primaryBackground}`,
              },
            ]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: ease, duration: 0.25 }}
            exit={{ opacity: 0 }}
          >
            {convertDateToHumanReadable(new Date(message.time))}
          </motion.div>
        </BodyPortal>
      )}
    </AnimatePresence>
  );
}

const EmojiDisplay = ({
  reactions,
  messageId,
  conversation,
}: {
  reactions?: ReactionList;
  messageId: string;
  conversation: Conversation;
}) => {
  const contacts = useContacts();
  const localUserPhoneNumber = ls.getUser();

  return (
    reactions &&
    Object.keys(reactions).length > 0 && (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "26px",
        }}
      >
        {Object.entries(reactions)
          .filter((reaction) => reaction[1].length > 0)
          .map((reaction, index) => {
            const isCustomReaction = reaction[0].startsWith("http");
            const emoji = reaction[0];
            const messages = reaction[1];
            let localReacted = false;
            const reactedFrom = messages.map((message) => {
              const from = message.From;

              const contact =
                contacts?.findWithNumber(from) ||
                WebGwContact.fromPhoneNumber(from)!;

              const fromLocal = isSamePhoneNumber(
                contact.getMainPhoneNumber(),
                localUserPhoneNumber
              );

              if (!localReacted && fromLocal) {
                localReacted = true;
              }

              return fromLocal ? "You" : contact.noNameReturnPhoneNumber();
            });

            const reactedContacts =
              reactedFrom.length > 2
                ? `${reactedFrom.slice(0, -1).join(", ")} and ${reactedFrom.slice(-1)}`
                : reactedFrom.join(" and ");

            const toolTipContent = (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "120px",
                  gap: "0.5em",
                }}
              >
                {isCustomReaction ? (
                  <img
                    css={{ width: "24px", height: "24px" }}
                    src={`${emoji}`}
                  />
                ) : (
                  <span
                    style={{
                      fontSize: "2em",
                      lineHeight: "1",
                      fontFamily: "Noto Color Emoji",
                    }}
                  >
                    {emoji}
                  </span>
                )}
                <span style={{ textAlign: "center", textWrap: "nowrap" }}>
                  <span style={{ fontWeight: "bold" }}>{reactedContacts}</span>{" "}
                  reacted with{" "}
                  {isCustomReaction ? (
                    <img
                      css={{
                        width: "16px",
                        height: "16px",
                        verticalAlign: "middle",
                      }}
                      src={`${emoji}`}
                    />
                  ) : (
                    <span style={{ fontFamily: "Noto Color Emoji" }}>
                      {emoji}
                    </span>
                  )}
                </span>
              </div>
            );

            // We dont support reacting on same custom reaction from remote
            const canAddOrRemove = localReacted || !isCustomReaction;

            return (
              <Tooltip
                key={index}
                tooltipContent={toolTipContent}
                placement="top"
                className="bg-tertiaryTextColor text-primaryTextColor"
              >
                <div
                  onClick={(e) => {
                    if (!canAddOrRemove) {
                      return;
                    }
                    e.stopPropagation();
                    void addOrRemoveEmoji(
                      emoji,
                      messageId,
                      conversation,
                      reactions
                    );
                  }}
                  onMouseOver={(e) => e.stopPropagation()}
                  style={{
                    cursor: canAddOrRemove ? "pointer" : "default",
                    marginLeft: "2px",
                    paddingRight: "5px",
                    paddingLeft: "5px",
                    backgroundColor: "#232b33",
                    borderRadius: "100px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isCustomReaction ? (
                    <img
                      css={{ width: "20px", height: "20px" }}
                      src={`${emoji}`}
                    />
                  ) : (
                    <span
                      style={{
                        minWidth: "15px",
                        fontFamily: "Noto Color Emoji",
                      }}
                    >
                      {emoji}
                    </span>
                  )}
                  {messages.length > 1 && (
                    <span
                      style={{
                        marginLeft: "0.5em",
                        fontSize: "0.8em",
                        fontWeight: "bold",
                      }}
                    >
                      {messages.length}
                    </span>
                  )}
                </div>
              </Tooltip>
            );
          })}
      </div>
    )
  );
};
