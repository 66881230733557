import { colors } from "@/styles/global.styles";
import mergeRefs2 from "@/utils/helpers/mergeRefs2";
import {
  arrow,
  autoPlacement,
  autoUpdate,
  FloatingArrow,
  FloatingPortal,
  hide,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles,
} from "@floating-ui/react";
import React, { useLayoutEffect, useState } from "react";

type XY = [string, string];
const placementOrigins: Record<Placement, XY> = {
  top: ["50%", "100%"],
  bottom: ["50%", "0%"],
  left: ["100%", "50%"],
  right: ["0%", "50%"],
  "bottom-end": ["100%", "0%"],
  "bottom-start": ["0%", "0%"],
  "top-end": ["100%", "100%"],
  "top-start": ["0%", "100%"],
  "left-end": ["100%", "0%"],
  "left-start": ["100%", "100%"],
  "right-end": ["0%", "0%"],
  "right-start": ["0%", "100%"],
};

type PopoverProps = {
  ref?: React.Ref<HTMLDivElement | null>;
  children: React.ReactNode;
  open: boolean;
  referenceElemRef: React.RefObject<HTMLElement | null>;
  placements?: Placement[];
  hideOnOverflow?: boolean;
  mountToBody?: boolean;
  extraFloatingStyles?: React.CSSProperties;
  floatingArrowProps?: Partial<React.ComponentProps<typeof FloatingArrow>>;
};

export default function Popover({
  ref,
  children,
  open,
  referenceElemRef,
  placements,
  hideOnOverflow = false,
  mountToBody = false,
  extraFloatingStyles,
  floatingArrowProps,
}: PopoverProps) {
  const [arrowRef, setArrowRef] = useState<SVGSVGElement | null>(null);
  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open,
    middleware: [
      autoPlacement(
        {
          boundary: "clippingAncestors",
          altBoundary: true,
          allowedPlacements: placements,
        },
        [placements]
      ),
      offset(5),
      shift({
        altBoundary: true,
        padding: 10,
      }),
      arrow({ element: arrowRef }),
      hideOnOverflow && hide(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps: _getReferenceProps, getFloatingProps } =
    useInteractions([click, dismiss, role]);

  useLayoutEffect(() => {
    refs.setReference(referenceElemRef.current);
  }, [refs, referenceElemRef]);

  const animationDuration = 100;
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: "scale(0.9) translateZ(0)",
    },
    close: {
      opacity: 0,
      transform: "scale(0.9) translateZ(0)",
    },
    common: ({ placement }) => {
      const [x, y] = placementOrigins[placement];

      const res = {
        transformOrigin: `calc(${x} - ${middlewareData.shift?.x ?? 0}px) calc(${y} - ${middlewareData.shift?.y ?? 0}px)`,
      };

      return res;
    },
    duration: animationDuration,
  });

  if (!isMounted) {
    return null;
  }

  const hidden = middlewareData.hide?.referenceHidden;

  const content = (
    <div
      ref={mergeRefs2<HTMLDivElement>(() => [ref, refs.setFloating])}
      {...getFloatingProps({
        style: {
          ...floatingStyles,
          zIndex: "9999",
          ...extraFloatingStyles,
        },
      })}
    >
      <div
        style={{
          ...transitionStyles,
          ...(hidden && {
            opacity: "0",
            pointerEvents: "none",
          }),
        }}
      >
        {children}
        <FloatingArrow
          ref={setArrowRef}
          context={context}
          className="z-10"
          fill={colors.tertiaryBackground}
          tipRadius={2}
          {...floatingArrowProps}
        />
      </div>
    </div>
  );

  if (mountToBody) {
    return <FloatingPortal>{content}</FloatingPortal>;
  }

  return content;
}
