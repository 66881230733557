import ArrowDown from "@/assets/ArrowDown";
import { overlayTransition } from "@/components/shared/DraggableOverlay";
import { colors } from "@/styles/global.styles";
import { useSnapshotAcceptUndefined } from "@/utils";
import { ease } from "@/utils/ease";
import { atoms } from "@/utils/helpers/atoms";
import { convertDateToHumanReadableShort } from "@/utils/helpers/time";
import Conversation from "@/utils/messaging/conversation/Conversation";
import {
  conversationsState,
  getSelectedConversation,
  useSelectedConversation,
} from "@/utils/messaging/conversation/ConversationState";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { isSameDay } from "date-fns/isSameDay";
import { useAtomValue } from "jotai";
import { AnimatePresence, motion, MotionConfig } from "motion/react";
import React, {
  RefObject,
  startTransition,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { proxy, useSnapshot } from "valtio";
import {
  usePreviousRef,
  usePreviousState,
} from "../../../utils/hooks/usePrevious";
import NmsMessage from "../../../utils/messaging/NmsMessage";
import parseNmsToChatMessage from "../../../utils/messaging/parseNmsToChatMessage";
import { confState } from "./chatConf";
import MessageDetails from "./components/MessageDetails";
import { MessageAreaRef, setMessageAreaRef } from "./messageAreaRef";
import MessageHolder from "./MessageHolder";
import { scrollToBottomRepeated } from "./util/cardUtils";

export default function MessageArea({
  chatBoxRef,
}: {
  chatBoxRef: RefObject<HTMLElement>;
}) {
  "use no memo";

  const snap = useSnapshot(conversationsState);
  const conversation = getSelectedConversation(snap);
  const messages = conversation?.getMessages();

  const olRef = useRef<MessageAreaRef>(null);
  const disableNewMsgAnimation = useRef(true);
  const scrollBarAtTheBottomRef = useRef(true);
  const lastMessagesLengthRef = usePreviousRef(messages?.length);
  const reply = useAtomValue(atoms.messaging.messageReply);

  const [showMessageDetails, setShowMessageDetails] = useState<
    { conversation: Conversation; message: NmsMessage } | undefined
  >();

  /**
   * creating state that gets updated in this parent component,
   * but is only read in the child component and thus doesn't cause a re-render for this component
   */
  const goToBottomBubbleState = useMemo(
    () => proxy({ val: { show: false, count: 0 } }),
    []
  );

  // Handles showing message details
  const handleShowMessageDetails = (
    conversation: Conversation,
    message: NmsMessage
  ) => {
    setShowMessageDetails({ conversation, message });
  };

  // Handles closing message details
  const handleCloseMessageDetails = () => {
    setShowMessageDetails(undefined);
  };

  // Checks if the scrollbar is at the bottom
  const checkNeedScrollToLastMessage = () =>
    scrollBarAtTheBottomRef.current ||
    messages?.[messages.length - 1]?.Direction === "Out";

  // Handles scrollbar position change
  const handleScrollBarBottomChange = (scrollBarAtTheBottom: boolean) => {
    scrollBarAtTheBottomRef.current = scrollBarAtTheBottom;
    goToBottomBubbleState.val.show = !scrollBarAtTheBottom;

    if (scrollBarAtTheBottom) {
      goToBottomBubbleState.val.count = 0;
    }
  };

  useLayoutEffect(() => {
    if (
      messages &&
      lastMessagesLengthRef.current &&
      messages.length !== lastMessagesLengthRef.current &&
      messages[messages.length - 1].Direction === "In"
    ) {
      goToBottomBubbleState.val.count +=
        messages.length - lastMessagesLengthRef.current;
    }
  }, [messages]);

  // Sets up scrolling behavior and ref updates
  useLayoutEffect(() => {
    if (!olRef.current) {
      console.error("olRef.current is null 1");
      return;
    }

    const parent = olRef.current.parentElement;
    if (!parent) return;

    olRef.current.scrollToBottom = () => {
      parent.scrollTop = parent.scrollHeight;
    };

    olRef.current.scrollToBottomSmooth = () => {
      olRef.current?.lastElementChild?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    };

    setMessageAreaRef(olRef.current);
  }, []);

  // Updates CSS properties for transition timing
  useEffect(() => {
    // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
    setTimeout(() => {
      if (!olRef.current) {
        console.error("olRef.current is null 2");
        return;
      }
      olRef.current.style.setProperty("--transition-time", "0.35s");
      olRef.current.style.setProperty("--t", "0.35s ease");
    });
  }, []);

  // Disables new message animation after a delay
  useEffect(() => {
    // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
    setTimeout(() => {
      disableNewMsgAnimation.current = false;
    }, 1000);
  }, []);

  // Automatically scrolls to bottom when new messages arrive
  useLayoutEffect(() => {
    if (olRef.current && scrollBarAtTheBottomRef.current) {
      const stopScroll = scrollToBottomRepeated({
        scrollElem: olRef.current,
        stopAfterDelayMs: 2000,
      });
      return stopScroll;
    }
  }, []);

  useEffect(() => {
    if (!olRef.current) return;

    const chatContainer = olRef.current;

    // Resize Observer: Auto-scroll when the last message expands
    const resizeObserver = new ResizeObserver(() => {
      if (scrollBarAtTheBottomRef.current) {
        olRef.current!.scrollToBottom();
      }
    });

    // Mutation Observer: Detects new messages and re-attaches ResizeObserver
    const mutationObserver = new MutationObserver(() => {
      resizeObserver.disconnect();
      const lastMessage = chatContainer.lastElementChild;
      if (lastMessage) resizeObserver.observe(lastMessage);
    });

    mutationObserver.observe(chatContainer, { childList: true });

    // Initial attachment to last message
    const lastMessage = chatContainer.lastElementChild;
    if (lastMessage) resizeObserver.observe(lastMessage);

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!olRef.current) return;

    const chatContainer = olRef.current;
    const scrollElem = chatContainer.parentElement;
    if (!scrollElem) return;

    const bottomSentinel = document.createElement("div");
    Object.assign(bottomSentinel.style, {
      height: "1px",
      transform: "translateY(-1px)",
    });

    scrollElem.append(bottomSentinel);

    // Intersection Observer: Detects if user scrolled to the bottom
    const bottomObserver = new IntersectionObserver(
      ([entry]) => handleScrollBarBottomChange(entry.isIntersecting),
      { root: scrollElem, threshold: 0, rootMargin: "5px 0px" }
    );

    bottomObserver.observe(bottomSentinel);

    return () => {
      bottomObserver.disconnect();
      bottomSentinel.remove();
    };
  }, [handleScrollBarBottomChange]);

  return (
    <>
      <ol
        ref={olRef}
        className="chat"
        css={{
          "--transition-time": "0s",
          minHeight: "100%",
          marginBottom: 0,
          contain: "paint",
          ...(reply && {
            [`& > *:not([data-message-id='${reply.id}'])`]: {
              opacity: "0.5 !important",
              pointerEvents: "none",
            },
          }),
        }}
      >
        {!conversation || !messages ? null : (
          <MessageAreaLimiter olRef={olRef}>
            {messages.map((message, idx) => (
              <MessageOuterHolder
                conversation={conversation}
                chatBoxRef={chatBoxRef}
                key={message["imdn.Message-ID"]}
                msgIdx={idx}
                messagesLength={messages.length}
                disableNewMsgAnimation={disableNewMsgAnimation.current}
                onCheckNeedScrollToLastMessage={checkNeedScrollToLastMessage}
                onShowMessageDetails={handleShowMessageDetails}
              />
            ))}
          </MessageAreaLimiter>
        )}
      </ol>
      <GoToBottomBubble
        onClick={() => olRef.current?.scrollToBottomSmooth()}
        state={goToBottomBubbleState}
      />
      <MotionConfig transition={overlayTransition}>
        <AnimatePresence>
          {showMessageDetails && (
            <div
              style={{
                position: "absolute",
                inset: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 9999,
              }}
            >
              <MessageDetails
                conversation={showMessageDetails.conversation}
                message={showMessageDetails.message}
                onClose={handleCloseMessageDetails}
              />
            </div>
          )}
        </AnimatePresence>
      </MotionConfig>
    </>
  );
}

function MessageAreaLimiter({
  children,
  olRef,
}: {
  children: React.ReactElement<
    React.ComponentProps<typeof MessageOuterHolder>
  >[];
  olRef: RefObject<MessageAreaRef | null>;
}) {
  const initial = 20;
  const increment = 10;
  const incrementOnScrollRatio = 0.9;
  const approximateMessageHeight = 60;

  const [elementsToRender, setElementsToRender] = useState(initial);
  const elementsToRenderRef = useRef(elementsToRender);

  const previousChildrenLength = usePreviousState(children.length);
  const diff = previousChildrenLength
    ? children.length - previousChildrenLength
    : 0;

  useEffect(() => {
    console.log("elementsToRender", elementsToRender);
  }, [elementsToRender]);

  useEffect(() => {
    const olElem = olRef.current;
    if (!olElem) return;
    const scrollElem = olElem.parentElement;

    if (!scrollElem) {
      console.warn("scrollElem is null", olElem);
      return;
    }

    const topPaddingElem = document.createElement("div");
    Object.assign(topPaddingElem.style, {
      width: "100%",
      background: "transparent", // switch to "blue" for debugging
    } satisfies React.CSSProperties);
    const elementsLeft = children.length - elementsToRenderRef.current;
    let topPaddingHeight = elementsLeft * approximateMessageHeight;
    const recalculateTopPaddingElemHeight = (heightToRemove: number) => {
      topPaddingHeight -= heightToRemove;
      topPaddingElem.style.height = `${topPaddingHeight}px`;
    };
    recalculateTopPaddingElemHeight(0);
    scrollElem.prepend(topPaddingElem);

    const topSentinel = document.createElement("div");
    Object.assign(topSentinel.style, {
      height: "1px",
      background: "transparent", // switch to "red" for debugging
    } satisfies React.CSSProperties);
    // this cannot be prepended or else the browser will treat it as a scroll anchor while more elements are being added to render
    scrollElem.append(topSentinel);

    const controller = new AbortController();

    let isIntersecting = false;

    const incrementElementsToRender = (inc = increment) => {
      startTransition(() => {
        setElementsToRender((prev) => {
          const newElementsToRender = Math.min(prev + inc, children.length);
          elementsToRenderRef.current = newElementsToRender;

          if (elementsToRenderRef.current >= children.length) {
            cleanup();
            return newElementsToRender;
          }

          return newElementsToRender;
        });
      });
    };

    let tryLoadMoreTimeout: number | undefined;
    const tryLoadMore = () => {
      if (!isIntersecting) return;

      incrementElementsToRender();

      clearTimeout(tryLoadMoreTimeout);
      tryLoadMoreTimeout = window.setTimeout(tryLoadMore, 150);
    };

    const topObserver = new IntersectionObserver(
      ([entry]) => {
        isIntersecting =
          entry.isIntersecting ||
          entry.boundingClientRect.top > entry.rootBounds!.height;
        tryLoadMore();
      },
      {
        root: scrollElem,
        threshold: 0,
      }
    );
    topObserver.observe(topSentinel);

    const updateTopSentinelTransform = (scrollHeight: number) => {
      topSentinel.style.transform = `translateY(-${scrollHeight * incrementOnScrollRatio}px)`;
    };

    let lastContentRectHeight: number | undefined;
    const resizeObserver = new ResizeObserver(([entry]) => {
      lastContentRectHeight ??= entry.contentRect.height;
      const contentRectHeightDiff =
        entry.contentRect.height - lastContentRectHeight;
      recalculateTopPaddingElemHeight(contentRectHeightDiff);

      updateTopSentinelTransform(entry.contentRect.height);

      // timeout to give some time for the intersection observer to update `isIntersecting`, if it happens at all
      clearTimeout(tryLoadMoreTimeout);
      tryLoadMoreTimeout = window.setTimeout(tryLoadMore, 100);
    });
    resizeObserver.observe(olElem);

    // if the user is idle for some time, render more messages
    const idleIncrement = 20;
    let idleTimer: number | undefined;
    const handleScrollIdle = () => {
      clearTimeout(idleTimer);
      idleTimer = window.setTimeout(() => {
        if (elementsToRenderRef.current < children.length) {
          incrementElementsToRender(idleIncrement);
        }
      }, 3000);
    };
    // initial call to handleScrollIdle to start the idle timer
    handleScrollIdle();
    scrollElem.addEventListener("wheel", handleScrollIdle, {
      passive: true,
      signal: controller.signal,
    });
    scrollElem.addEventListener("touchend", handleScrollIdle, {
      passive: true,
      signal: controller.signal,
    });

    const cleanup = () => {
      resizeObserver.disconnect();
      topObserver.disconnect();
      topPaddingElem.remove();
      topSentinel.remove();
      clearTimeout(tryLoadMoreTimeout);
      clearTimeout(idleTimer);
      controller.abort();
    };

    return cleanup;
  }, [children.length, increment]);

  if (elementsToRender >= children.length) {
    return children;
  }

  return children.slice(
    Math.max(0, children.length - (elementsToRender + diff))
  );
}

type MessageComponentProps = {
  msgIdx: number;
  messagesLength: number;
  disableNewMsgAnimation: boolean;
  chatBoxRef: RefObject<HTMLElement>;
  conversation: Conversation;
  onCheckNeedScrollToLastMessage: () => boolean;
  onShowMessageDetails: (
    conversation: Conversation,
    message: NmsMessage
  ) => void;
};
function MessageOuterHolder({
  msgIdx,
  messagesLength,
  disableNewMsgAnimation,
  chatBoxRef,
  conversation,
  onCheckNeedScrollToLastMessage,
  onShowMessageDetails,
}: MessageComponentProps) {
  const conf = useSnapshot(confState);
  const [fallbackToGenericFile, setFallbackToGenericFile] = useState(false);

  const messages = useSelectedConversation()!.getMessages();

  const { prevMessageSnap, messageProxy, messageSnap, nextMessageSnap } =
    useSurroundingMessageSnapshots(messages, msgIdx);

  const chatMessage = useMemo(
    () => parseNmsToChatMessage(messageSnap, fallbackToGenericFile),
    [
      messageSnap,
      messageSnap.history,
      messageSnap.deleted,
      fallbackToGenericFile,
    ]
  );
  if (!chatMessage) return null;

  // remove suggested chip list if not last 2 messages
  if (msgIdx !== messagesLength - 1 && msgIdx !== messagesLength - 2) {
    delete chatMessage.suggestedChipList;
  }

  const separateTimeMs = conf.messageSeparateTimeMs ?? 5 * 60 * 1000;

  const separateFromLastMessage =
    !prevMessageSnap ||
    prevMessageSnap.Direction !== messageSnap.Direction ||
    messageSnap.Date.getTime() - prevMessageSnap.Date.getTime() >
      separateTimeMs ||
    (prevMessageSnap._isBeingDeleted &&
      // check the previous previous message
      messages[msgIdx - 2] &&
      (messages[msgIdx - 2].Direction !== prevMessageSnap.Direction ||
        prevMessageSnap.Date.getTime() - messages[msgIdx - 2].Date.getTime() >
          separateTimeMs));

  const separateFromNextMessage =
    !nextMessageSnap ||
    nextMessageSnap.Direction !== messageSnap.Direction ||
    nextMessageSnap.Date.getTime() - messageSnap.Date.getTime() >
      separateTimeMs;

  const sameDayAsPreviousMessage = prevMessageSnap
    ? prevMessageSnap._isBeingDeleted &&
      // check the previous previous message
      messages[msgIdx - 2]
      ? isSameDay(messageSnap.Date, messages[msgIdx - 2].Date)
      : isSameDay(messageSnap.Date, prevMessageSnap.Date)
    : false;

  const sameContactAsPreviousMessage = prevMessageSnap
    ? isSamePhoneNumber(messageSnap.From, prevMessageSnap.From)
    : false;

  const handleFallbackToGenericFile = () => {
    setFallbackToGenericFile(true);
  };

  return (
    <React.Fragment key={messageSnap.reactKeyIdList}>
      {!sameDayAsPreviousMessage && (
        <DateHeader
          date={new Date(chatMessage.time)}
          isBeingDeleted={messageSnap._isBeingDeleted}
          forceNoAnimation={disableNewMsgAnimation}
        />
      )}
      <MessageHolder
        conversation={conversation}
        showIncomingContactHeader={
          conversation.getIsGroupChat() &&
          chatMessage.direction === "In" &&
          !sameContactAsPreviousMessage
        }
        sameDayAsPreviousMessage={sameDayAsPreviousMessage}
        chatBoxRef={chatBoxRef}
        nmsMessageProxy={messageProxy}
        message={chatMessage}
        forceNoAnimation={disableNewMsgAnimation}
        roundBorderTop={separateFromLastMessage}
        roundBorderBottom={separateFromNextMessage}
        isLastMessage={
          !nextMessageSnap ||
          // check if this message will be the last message after the next message is deleted
          (nextMessageSnap._isBeingDeleted && msgIdx === messages.length - 2)
        }
        onCheckNeedScrollToLastMessage={onCheckNeedScrollToLastMessage}
        onFallbackToGenericFile={handleFallbackToGenericFile}
        onShowMessageDetails={onShowMessageDetails}
      />
    </React.Fragment>
  );
}

function DateHeader({
  date,
  isBeingDeleted,
  forceNoAnimation,
}: {
  date: Date;
  isBeingDeleted: boolean;
  forceNoAnimation: boolean;
}) {
  return (
    <motion.div
      css={{
        textAlign: "center",
        fontSize: "0.8em",
        fontWeight: "bold",
        overflow: "hidden",
        lineHeight: "1",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
      initial={
        forceNoAnimation
          ? false
          : { opacity: 0, paddingTop: 0, paddingBottom: 0, maxHeight: 0 }
      }
      animate={
        isBeingDeleted
          ? { opacity: 0, paddingTop: 0, paddingBottom: 0, maxHeight: 0 }
          : {
              opacity: 1,
              maxHeight: "40px",
              paddingTop: "1rem",
              paddingBottom: "0.5rem",
            }
      }
      transition={{ duration: 0.35, ease: ease }}
    >
      {convertDateToHumanReadableShort(date, false, false)}
    </motion.div>
  );
}

function GoToBottomBubble({
  onClick,
  state,
}: {
  onClick: () => void;
  state: { val: { show: boolean; count: number } };
}) {
  const {
    val: { show, count: nbMessages },
  } = useSnapshot(state);

  if (!show) {
    return null;
  }

  const is3Digits = nbMessages > 99;
  const is2Digits = nbMessages > 9;

  const nbMessagesText = is3Digits ? "99+" : nbMessages;
  const style = {
    width: is3Digits ? "1.4em" : is2Digits ? "1.1em" : "0.9em",
    height: is3Digits ? "1.4em" : is2Digits ? "1.1em" : "0.9em",
    top: is3Digits ? "-17px" : is2Digits ? "-12px" : "-9px",
  };

  return (
    /**
     * Goal of this sticky div with a 0 height helps to keep the bubble at the bottom without any space taking, the embedded div absolute will take over it.
     * Directly using an absolute div wont keep the bubble at the bottom when scrolling
     */
    <div
      style={{
        position: "sticky",
        bottom: "0",
        zIndex: "1",
        height: "0",
      }}
    >
      <div
        css={{
          cursor: "pointer",
          borderRadius: "50%",
          background: colors.secondaryBackground,
          width: "34px",
          height: "34px",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          filter: `drop-shadow(0px 2px 2px #151719)`,
        }}
        onClick={onClick}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            visibility: nbMessages > 0 ? "visible" : "hidden",
            borderRadius: "50%",
            borderColor: colors.primaryBackground,
            borderStyle: "solid",
            borderWidth: "1px",
            backgroundColor: colors.primaryAccentColor,
            position: "absolute",
            ...style,
          }}
        >
          <span style={{ fontSize: "0.7em", fontWeight: "bold" }}>
            {nbMessagesText}
          </span>
        </div>

        <ArrowDown />
      </div>
    </div>
  );
}

function useSurroundingMessageSnapshots(
  messages: readonly NmsMessage[] | NmsMessage[],
  msgIdx: number
) {
  const messageProxy = messages[msgIdx];

  const messageSnap = useSnapshot(messageProxy);

  const prevMessageProxy = messages[msgIdx - 1] as NmsMessage | undefined;
  const prevMessageSnap = useSnapshotAcceptUndefined(prevMessageProxy);

  const nextMessageProxy = messages[msgIdx + 1] as NmsMessage | undefined;
  const nextMessageSnap = useSnapshotAcceptUndefined(nextMessageProxy);

  return { prevMessageSnap, messageProxy, messageSnap, nextMessageSnap };
}
