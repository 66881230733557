import RenderOnIdle from "@/components/shared/RenderOnIdle";
import { colors } from "@/styles/global.styles";
import { ease } from "@/utils/ease";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { getSharedIntersectionObserver } from "@/utils/helpers/getSharedIntersectionObserver";
import { useMediaExtraInfo } from "@/utils/hooks/useMediaExtraInfos";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { animate } from "motion/react";
import { RefObject, useEffect, useLayoutEffect, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSnapshot } from "valtio";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import NmsMessage from "../../../utils/messaging/NmsMessage";
import MessageActions from "./components/MessageActions";
import { messageAreaRef } from "./messageAreaRef";
import ContactCard from "./messages/ContactCard";
import {
  StatusDivRef,
  StatusTimeDiv,
  TimeSent,
  TimeSentRef,
} from "./messages/MessageStatus";
import RichcardMessage from "./messages/RichcardMessage";
import TextMessage from "./messages/TextMessage";
import { liCss, msgContentCss, msgCss, textMessageCss } from "./messages/style";
import { ChatMessage } from "./typings";
import { MediaExtraInfo } from "./typings/moderatorChatbotInfo";
import {
  isOverflownY,
  scrollToBottomDuringAnimation,
  useRoundBordersCss,
} from "./util/cardUtils";

type MessageHolderProps = {
  nmsMessageProxy: NmsMessage;
  message: ChatMessage;
  forceNoAnimation: boolean;
  roundBorderTop: boolean;
  roundBorderBottom: boolean;
  isLastMessage: boolean;
  chatBoxRef: RefObject<HTMLElement>;
  showIncomingContactHeader: boolean;
  sameDayAsPreviousMessage: boolean;
  conversation: Conversation;
  onCheckNeedScrollToLastMessage: () => boolean;
  mediaExtraInfo?: MediaExtraInfo;
  onFallbackToGenericFile?: () => void;
  onShowMessageDetails: (
    conversation: Conversation,
    message: NmsMessage
  ) => void;
};

function expandOrShrink({
  liRef,
  shrink = false,
  onCheckNeedScrollToLastMessage = () => true,
}: {
  liRef: React.RefObject<HTMLLIElement | null>;
  shrink?: boolean;
  onCheckNeedScrollToLastMessage?: () => boolean;
}) {
  const liElem = liRef.current;
  if (!liElem) return {};
  const firstElement = liElem.firstElementChild as HTMLElement | null;
  if (!firstElement) return {};

  const { height } = firstElement.getBoundingClientRect();

  if (height === 0) return {};

  // lock the inner height of the message holder
  firstElement.style.minHeight = "0";

  liElem.style.willChange = "max-height";
  liElem.style.overflow = "hidden";

  const [scrollToBottom, stopScroll] = !shrink
    ? scrollToBottomDuringAnimation()
    : [];

  const animationDuration = 0.35;

  const animation = animate(
    liElem,
    shrink
      ? {
          maxHeight: [`${height}px`, "0px"],
          opacity: [1, 0],
        }
      : {
          maxHeight: ["0px", `${height}px`],
        },
    {
      duration: animationDuration,
      ease: ease,
      onUpdate: () => {
        if (onCheckNeedScrollToLastMessage()) {
          scrollToBottom?.();
        }
      },
      onComplete: () => {
        queueMicrotask(() => {
          stopScroll?.();
          if (!shrink) {
            // max height needs to be removed is because the margin-top may need to be animated if a message above this one is deleted
            liElem.style.maxHeight = "";
          }
          liElem.style.willChange = "";
          liElem.style.overflow = "";

          firstElement.style.minHeight = "";
        });
      },
    }
  );

  return { animation, stopScroll };
}

export default function MessageHolder(props: MessageHolderProps) {
  // remove the suggestions if the message is not the most recent one or was previously rendered with suggestions
  const wasLastMessage = usePrevious(props.isLastMessage);
  if (!wasLastMessage && !props.isLastMessage) {
    // suggestions cannot be rendered when this is deleted
    // delete props.message.suggestedChipList;
  }
  const contact = props.showIncomingContactHeader
    ? props.conversation.participants.find(
        (participant) =>
          !!participant.filterContactOnPhone(props.nmsMessageProxy.From)
      ) ||
      // In case no participant found, we simply use the phone number of the message
      // This can occur on group chats that are still syncing and not having their participant list known yet
      WebGwContact.fromPhoneNumber(props.nmsMessageProxy.From)
    : undefined;

  const liRef = useRef<HTMLLIElement>(null);

  const deletedMessageRoundBorders = useRoundBordersCss(
    props.message.direction,
    props.roundBorderTop,
    props.roundBorderBottom
  );

  useLayoutEffect(() => {
    if (props.forceNoAnimation || !props.isLastMessage) {
      return;
    }

    const elem = liRef.current;
    if (!elem) return;

    elem.style.visibility = "hidden";

    // ! this setTimeout is needed so that the rich card contents are shrunk before the animation starts so the height calculation inside `expandOrShrink` is correct
    // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
    setTimeout(() => {
      elem.style.visibility = "";
      expandOrShrink({
        liRef,
        onCheckNeedScrollToLastMessage: props.onCheckNeedScrollToLastMessage,
      });
    });
  }, []);

  const { message, chatBoxRef } = props;
  let MessageComponent:
    | typeof TextMessage
    | typeof RichcardMessage
    | typeof ContactCard
    | undefined;
  if (message.textMessage) {
    MessageComponent = TextMessage;
  } else if (message.richcardMessage) {
    MessageComponent = RichcardMessage;
  } else if (message.contactCard) {
    MessageComponent = ContactCard;
  }

  const showStatus = props.isLastMessage && message.direction === "Out";
  const statusDivRef = useRef<StatusDivRef>(null!);

  const participants = props.conversation.participants;
  const isChatbot = participants[0]?.isChatbot;
  const mediaExtraInfo = useMediaExtraInfo(participants[0]);

  const skipSendDisplayed = useRef(
    message.status === "read" || message.direction === "Out"
  );

  useEffect(() => {
    const liElem = liRef.current;
    if (!liElem) return;

    const { observe, unobserve } = getSharedIntersectionObserver({
      rootRef: chatBoxRef,
      observerOptions: {
        rootMargin: "20px 0px",
      },
    });

    observe(liElem, (entry) => {
      liElem.style.contentVisibility = entry.isIntersecting
        ? "visible" // disable content-visibility instead of setting to auto to avoid rendering bugs
        : "hidden";

      if (entry.isIntersecting && !skipSendDisplayed.current) {
        void props.nmsMessageProxy.sendDisplayed();
        skipSendDisplayed.current = true;
      }
    });

    return () => {
      unobserve(liElem);
    };
  }, []);

  const nmsMessageSnap = useSnapshot(props.nmsMessageProxy);

  const messageComponentWrapperRef = useRef<HTMLSpanElement>(null!);
  const timeSentRef = useRef<TimeSentRef>(null);
  const timeSentDisplayTimeout = useRef<number | undefined>(undefined);
  const hideTimeSent = () => {
    clearTimeout(timeSentDisplayTimeout.current);
    timeSentRef.current?.hide();
    messageComponentWrapperRef.current.onmousemove = null;
  };

  if (!MessageComponent) return null;

  const isSingleRichCardWithMedia =
    !!message?.richcardMessage?.message.generalPurposeCard?.content?.media
      ?.mediaUrl;

  const isCarousel =
    !!message?.richcardMessage?.message.generalPurposeCardCarousel;

  const handleFallbackToGenericFile = () => {
    props.onFallbackToGenericFile?.();
  };

  const removeTopMargin =
    !props.sameDayAsPreviousMessage ||
    (props.showIncomingContactHeader && !!contact);

  const isNewMessage = !props.forceNoAnimation;

  return (
    /* wrapped with ErrorBoundary just in case there is an exception in the message somehow */
    <ErrorBoundary fallback={<></>}>
      <li
        ref={liRef}
        className={message.direction}
        data-message-id={message.msgId}
        onClick={(e) => {
          if (e.ctrlKey) {
            e.stopPropagation();
            console.log(e.shiftKey ? props.nmsMessageProxy : nmsMessageSnap);
          }
        }}
        css={liCss}
        style={
          // new message would be auto hidden without this condition
          !isNewMessage
            ? {
                // start off as auto (hidden), observer will make it visible when needed
                contentVisibility: "auto",
              }
            : undefined
        }
      >
        <div
          css={[
            msgCss.root,
            message.direction === "In" ? msgCss.in : msgCss.out,
          ]}
        >
          {contact && (
            <div
              className="contact-name"
              css={{
                marginTop: "1em",
                fontSize: "0.8em",
                color: colors.secondaryTextColor,
              }}
            >
              {contact.noNameReturnPhoneNumber()}
            </div>
          )}

          <span
            css={[
              {
                position: "relative",
              },
              isSingleRichCardWithMedia
                ? isChatbot
                  ? msgCss.richCardMediaStandalone
                  : {}
                : isCarousel
                  ? { width: "100%", cursor: "grab" }
                  : {},
            ]}
          >
            <span
              ref={messageComponentWrapperRef}
              css={[
                msgCss.message,
                message.direction === "In" ? msgCss.in : msgCss.out,
                isCarousel ? { width: "100%" } : {},
              ]}
              onMouseEnter={(e) => {
                if (e.buttons !== 0 || !timeSentRef.current) return;

                const elem = e.currentTarget;

                clearTimeout(timeSentDisplayTimeout.current);
                timeSentDisplayTimeout.current = +setTimeout(() => {
                  if (
                    messageAreaRef?.parentElement &&
                    isOverflownY(elem, messageAreaRef.parentElement)
                  ) {
                    return;
                  }

                  const { left, right, top } = elem.getBoundingClientRect();
                  timeSentRef.current?.show({
                    left,
                    right,
                    top,
                    incomingMessage: props.message.direction === "In",
                  });

                  const distanceToHide = 20;
                  let distanceMoved = 0;
                  const handleMouseMove = (e: MouseEvent) => {
                    distanceMoved +=
                      Math.abs(e.movementX) + Math.abs(e.movementY);

                    if (distanceMoved > distanceToHide) {
                      hideTimeSent();
                    }
                  };
                  elem.onmousemove = handleMouseMove;
                }, 500);
              }}
              onMouseLeave={hideTimeSent}
              onMouseDown={hideTimeSent}
            >
              {message.deleted ? (
                <div
                  css={[
                    msgContentCss,
                    textMessageCss,
                    deletedMessageRoundBorders,
                    {
                      display: "flex",
                      flexDirection: "column",
                      alignItems:
                        message.direction === "In" ? "flex-start" : "flex-end",
                      fontStyle: "italic",
                      marginTop: removeTopMargin ? "0" : undefined,
                      opacity: 0.5,
                    },
                  ]}
                >
                  This message was deleted
                </div>
              ) : (
                <MessageComponent
                  message={message}
                  uploadProgress={nmsMessageSnap.uploadProgress}
                  removeTopMargin={removeTopMargin}
                  isLastMessage={props.isLastMessage}
                  roundBorderTop={props.roundBorderTop}
                  roundBorderBottom={props.roundBorderBottom}
                  direction={message.direction}
                  mediaExtraInfo={mediaExtraInfo}
                  onFallbackToGenericFile={handleFallbackToGenericFile}
                  showReplyMessage={true}
                />
              )}
            </span>

            <TimeSent ref={timeSentRef} message={message} />

            {
              /* Right now we disable the message actions for the chatbot, main reason is our components structure need the carousel to take 100% of the width in order for the dragging to work.
                A simple solution would be to set as prop the message action in the MessageComponent to directly include it in each component view*/
              !isChatbot && (
                <RenderOnIdle
                  renderOnHoverRef={liRef}
                  onIdleOptions={{ priority: "background" }}
                >
                  <MessageActions
                    message={props.nmsMessageProxy}
                    conversation={props.conversation}
                    reactions={nmsMessageSnap.reactions}
                    beforeDelete={async (softDelete: boolean) => {
                      if (softDelete) {
                        return;
                      }

                      const { animation } = expandOrShrink({
                        liRef,
                        shrink: true,
                      });
                      // eslint-disable-next-line react-compiler/react-compiler
                      props.nmsMessageProxy._isBeingDeleted = true;
                      await animation;
                    }}
                    direction={message.direction}
                    onShowMessageDetails={props.onShowMessageDetails}
                  />
                </RenderOnIdle>
              )
            }
          </span>
          <StatusTimeDiv
            ref={statusDivRef}
            showStatus={showStatus}
            message={message}
            reactions={nmsMessageSnap.reactions}
            imdns={nmsMessageSnap.imdns?.imdn}
            conversation={props.conversation}
            isMessageEdited={
              !message.deleted && nmsMessageSnap.history.length > 0
            }
          />
        </div>
      </li>
    </ErrorBoundary>
  );
}
